.card-otp-input {
  margin-right: 10px;
}

.card-otp-input input {
  width: 2.2em !important;
  text-align: center;
  border-radius: 10px;
  height: 2em;
}

.min-w-592 {
  min-width: 592px;
}
.max-w-175 {
  max-width: 175px;
}

.user-list {
  max-height: 576px;
}

.max-w-1000 {
  max-width: 1000px;
}

.right-12 {
  right: 12px;
}

.top-10 {
  top: 10px;
}

.top-14 {
  top: 14px;
}

.height-50 {
  height: 80%;
}

#toast-container {
  z-index: 99;
  top: 100px;
}

/* common tooltip */

.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}

.tooltip .bottom {
  min-width: 212px;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 6px 10px;
  background-color: #eeeeee;
  background-color: rgb(234, 179, 8);
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
}

.tooltip:hover .bottom {
  display: block;
}

.tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .bottom i::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: rgb(234, 179, 8);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.tooltip .top {
  min-width: max-content;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 6px 10px;
  background-color: rgb(234, 179, 8);
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: rgb(234, 179, 8);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
